@import "font-awesome";
@import "bootstrap";
 
@import "bootstrap-select";
@import "elements";

//@import "bootstrap/bootstrap";

@btn-font-weight: 300;
@font-size-base: 14px;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
@font-family-sans-serif: 'Source Sans Pro', sans-serif;


.proxima{
	.proxima();
}
.uppercase{
	text-transform:uppercase;
}
.normal{
	text-transform: none;
}

html, body {
	height:100%; /* needed for container min-height */
}

body, label, .checkbox label {
	font-weight: 300;
}

a {
	.transition(0.4s);
	text-decoration: none;
	&:hover {
		text-decoration: none;
		.transition(0s);
	}
	&:visited {
		text-decoration: none !important
	}
	&.hnegro:hover{
		color:#000;
	}
}

img{
	width:100%;
	height: auto;
}

h1, h2, h3, h4{
	text-align:center;
	font-weight:100;
	text-transform: uppercase;
	a{
		color: #000;
		&:hover{
			color: @main-color;
		}
	}
}

h1{
	text-transform: None;
	font-size: 30px;
	@media only screen and (max-width: @screen-xs) {
		font-size: 26px;
	}
}
h1.main, h1.principal{
	margin-bottom:60px;
}

h1.title, h2.title{
	margin-bottom:30px;
	margin-top:30px;
	padding: 0 80px;
	text-transform:none;
	@media only screen and (max-width: @screen-xs) {
		padding: 0 20px;
	}
}


h2{
	font-size: 30px;
	@media only screen and (max-width: @screen-xs) {
		font-size: 26px;
	}
}

.no-show{
	display:none;
}

#page{
	margin: 0 5%;
	overflow:hidden;
	min-height:85%;
	@media only screen and (max-width: 1400px) {
	    
	}
	@media only screen and (max-width: 1025px) {
		.item-page{
			margin: 0 -2%;
			.col-inside2{
				margin-left: 20px;
  				margin-right: 20px;

			}
		}
	}
	@media only screen and (max-width: @screen-xs) {
		.item-page{
			margin: 0 -2%;
			.col-inside2{
				margin-left: 0px;
  				margin-right: 0px;
			}
		}
	}
}

.main{
	/* width: 90%;*/
	/*max-width:1400px;*/
	margin: auto;
}

.container-fluid{
	max-width:1400px;
}

.footer{
	width:100%;
	background: @main-color;
	color: @main-color-negative;
	padding: 10px;
	text-align:center;
	margin-top: 20px;
	font-weight:400;
	font-size:12px;
	text-transform:uppercase;
	margin-bottom:24px;
	a{
		color: @main-color-negative;
	}
	ul{
		list-style:none;
		margin:0;
		padding:0;
		li{
			display:inline-block;
		}
		li:after{
			content:"|";
			margin: 10px;
		}
		li:last-child:after{
			content:"";
			margin: 0;
		}
	}
}

#header{
	position:fixed;
	width: 100%;
	z-index: 9999;
	background: #fff;
	top: 0;
	.container{
		width: 90%;
		max-width:1400px;
		margin:auto;
	}
	.logo{
		margin: 25px 30px 20px 50px;
		@media only screen and (max-width: @screen-md) {
			margin: 25px 30px 20px 30px;
		}
		@media only screen and (max-width: @screen-sm) {
			margin: 25px 30px 20px 10px;
		}
		@media only screen and (max-width: @screen-xs) {
			margin: 25px 30px 20px 0px;
		}
		img {
		  max-width: 256px;
		  min-width: 160px;
		}
		a{
			color: #000;
		}
		span {
		  font-size: 230px;
		  line-height: 10px;
		  display: block;
		    margin: -80px auto;
		  @media only screen and (max-width: @screen-md) {
		  	font-size: 210px;
		  	margin: -70px auto;
		  }
		  @media only screen and (max-width: @screen-sm) {
		  	font-size: 190px;
		  	margin: -60px auto;
		  }
		  @media only screen and (max-width: @screen-xs) {
		  	font-size: 160px;
		  	margin: -50px auto;
		  }
		}
	}
	.menu{
		text-align: right;
		padding: 30px 0px 25px;
		position: relative;
		.proxima();
		.toggle{
			cursor:pointer;
			.transition(0.4s);
			span.text{
				text-transform:uppercase;
				.proxima(300);
				padding-right: 18px;				
				line-height:36px;
				@media only screen and (max-width: @screen-xs) {
			      display: none;
			    }
			}
			span.icon{
				display: inline-block;
				vertical-align: middle;
 				vertical-align: -webkit-baseline-middle;
				font-size:36px;
				line-height:36px;
				&:hover{
					color: @main-color;
				}
			}
		}
	}
}
.menu-content{
	padding:50px;

	@media only screen and (max-width: @screen-xs) {
		padding:16px;
	}
	position:fixed;
	top:0;
	right:0;
	z-index:9999;
	background: #fff;
	width:50%;
	height:100%;
	text-transform: uppercase;
	.proxima();
	.translate(100%);
	.transition(0.4s);
	.inside{
		position: relative;
		top: 50%;
		.translate(0, -50%);
		text-align:center;
	}
	&.move{
		.translate(0%);
	}
	.icon{
		display: inline-block;
		vertical-align: middle;
	}
	ul{
		list-style: none;
		margin:0;
		padding:0;
		li{
			margin-bottom: 26px;
			@media only screen and (max-width: @screen-sm) {
				margin-bottom: 10px;
			}
			@media only screen and (max-width: @screen-xs) {
				margin-bottom: 10px;
			}
			&.collection a{
				color:#000;
				&:hover{
					color: @main-color;
				}
			}
			.icon{ 
				font-size:22px;   
				line-height: 18px;
			}
			.space{
				width:4px;
			}
		}
		&.pages{
			margin: 50px auto;
			width:400px;

			@media only screen and (max-width: @screen-md) {
				margin: 50px 20%;
				width:auto;
			}
  			padding: 50px 20px 20px;
			border-top: 1px solid @main-color;
			border-bottom: 1px solid @main-color;

			@media only screen and (max-width: @screen-sm) {
				margin: 15px 20%;
  				padding: 20px 5px 5px;
			}

			@media only screen and (max-width: @screen-sm) {
				margin: 15px 5%;
  				padding: 20px 5px 5px;
			}
		}
	}
	
	.social{
		h3{
			display:none;
			.collection a{
				color:#000;
				&:hover{
					color: @main-color;
				}
			}
		}
		/* delete

		margin: 20px 20px 0;
		text-align:center;
		font-size:30px;

		ul li{
			display: inline-block;
			margin-bottom: 0;
			padding: 0 10px;
			.icon{
				font-size:30px!important;
			}
			&:first-child{
				padding: 0 10px 0 0;
			}
			&:last-child{
				padding: 0 0 0 10px;
			}
			@media only screen and (max-width: 1025px) {
				padding: 0 5px 0 !important;

				&:first-child{
					padding: 0 5px 0 0!important;
				}
				&:last-child{
					padding: 0 0 0 5px!important;
				}
			}
		}
		*/
	}
	@media only screen and (max-width: @screen-sm) {
		width:75%;
	}
	@media only screen and (max-width: @screen-xs) {
		width:100%;
	}
}

.menu-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	z-index: 9;
	opacity: 0;
	display: none;
	.transition(0.8s);
	&.show{
		.transition(0.8s);
		display: block;
		opacity: .8;
	}
}

.page {
	.transition(0.5s);
	a {
  		text-decoration: none;
	}
	&.move {
		.translate(-25%);
	}
	.scroll-down{
		margin: 20px auto 0;
		text-align: center;
		width: 100%;
		position: absolute;
		cursor:pointer;
		img{
			border-radius:50%;
			width:auto;
		}
		@media only screen and (max-width: @screen-xs) {
			display:none;
		}
	}
	.social{
		border-top: 1px solid @main-color;
	}
	.social, .share{
		/* delete 
		margin: 20px 20%;
		@media only screen and (max-width: @screen-sm) {
			margin: 15px 15%;
		}

		@media only screen and (max-width: @screen-sm) {
			margin: 15px 5%;
		}
		text-align:center;
		font-size:30px;
		h3{
			color: #000;
			.proxima(600);
			font-size:14px
		}
		ul{
			list-style: none;
			margin:0;
			padding:0;
			li{
				display: inline-block;
				padding: 0 10px 0;
				a:hover{
					color:#000;
				}
				&:first-child{
					padding: 0 10px 0 0;
				}
				&:last-child{
					padding: 0 0 0 10px;
				}
			}
		}
		*/	
	}
	.social{
		h3{
			margin: 24px 0 0;
		}
		ul{
			margin-top: 32px;
		}
	}
	.share{
		border-top:0;
		margin: 20px 0;
		h3{
			color: #000;
			.proxima(600);
		}
	}
}


.item-page{
	.item-desc{
		margin-top: 100px;
		margin-bottom: 40px;
		@media only screen and (max-width: @screen-sm) {
			margin-top: 60px;
		}
		@media only screen and (max-width: @screen-xs) {
			margin-top: 0px;
		}

		h4{
			color: @main-color;
			.proxima(300);
			font-size:12px;
			margin-bottom:0;
		}
		.item-nav{
			font-size: 30px;
			span{
				padding: 0 4%;
				font-size:36px;
			}
			a{
				color:#000;
				&:hover{
					color: @main-color;
				}
				span{
					font-size:20px;
				}
			}
		}
		.item-back{
			margin:25px 0 30px;
			border-top: 1px solid @main-color;
			border-bottom: 1px solid @main-color;
			padding: 20px 0;
			.proxima(600);
		}
		.dofav, .favdone{
			margin-top: 35px;
			color: @main-color;
			cursor: pointer;
			&:hover{
				color:#000;
			}
			span{
				font-size:15px;
			}
			.icon{
				font-size:22px;
				line-height:18px;
				display: inline-block;
				vertical-align: middle;
			}
			.add-text{
				.proxima(600);
				font-size:12px;
			}
		}
		.favdone{
			display:none;
			a:hover{
				color:#000;
			}
		}	
	}
	.item-padding{
		@media only screen and (max-width: @screen-sm) {
			margin-right:1px;
		}
		/*
		@media only screen and (max-width: @screen-xs) {
			margin-right:0px;
		}
		*/
		&:last-child{
			@media only screen and (max-width: @screen-sm) {
				margin-right:0px;
				margin-left:1px;
			}
			/*
			@media only screen and (max-width: @screen-xs) {
				margin-right:0px;
				margin-left:0px;
			}
			*/
		}
	}
	img{
		margin-bottom: @row-padding-item;
	}

	.col-xs-12{
		@media only screen and (max-width: @screen-xs) and (orientation: landscape) {
			width:50%;
		}
	}
}

.parallax{
	position:relative;
	background-position:center center;
	background-attachment:fixed;
	background-size: 100%;
	background-repeat: no-repeat;
	clip: rect(auto, auto, auto, auto);
	-webkit-mask-image: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 100%);
    top: 0;
    left: 0;
}

.row{
	.col-item{
		/*margin: 0 -@grid-gutter-width/2;*/
		margin: 1px 1px;
	}
	.col-inside{
		margin-left: @row-inside-margin;
		margin-right: @row-inside-margin;
		@media only screen and (max-width: @screen-sm) {
			margin-left: 0;
			margin-right: 0;
		}
	}
	.col-inside2{
		margin-left: @row-inside-margin2;
		margin-right: @row-inside-margin2;
		@media only screen and (max-width: @screen-sm) {
			margin-left: 0;
			margin-right: 0;
		}
	}
	.row{
		margin:0;
	}
	&.no-glutter{
		margin:0;
	}
}

.col-item{
	position:relative;
	overflow:hidden;
	.fav-undone{
		position:absolute;
		top:30px;
		right:30px;
		cursor:pointer;
		color: @link-color;
		z-index:999;
		&:hover{
			color: #000;
		}
	}
	.caption{
		text-transform: uppercase;
		.proxima(300);
		position:absolute;
		width:100%;
		height:100%;
		top:0;
		left:0;
		.transition(.4s, ease-out);
		text-align:center;
		text-transform:uppercase;
		span{
			opacity:0;
			.transition(.4s, ease-out);
			position: relative;
			bottom: -100%;
			margin: 0 15% 0 15%;
			padding: 23px 0;
			border-top: 1px solid;
			border-bottom: 1px solid;
			display:block;
		}
		&:hover{
			background: @list-caption-bg;
			span{
				opacity:1;
				.vertical-align();
			}
		}
	}
	.name{
		position: absolute;
		font-weight: 400;
		bottom: 10px;
		right: 10px;
		background: #fff;
		min-width: 40px;
		height: 40px;
		border-radius: 20px;
		padding: 0 10px;
		text-align: center;
		line-height: 40px;
		.transition(.6s, easy-in-out);
	}
	&:hover{
		.name{
			.transition(0s, ease-in-out);
			background: @link-color;
			color:#fff;
		}
	}
}

.padding-top{
	padding-top:@row-padding-vertical;
	@media only screen and (max-width: @screen-xs) {
		padding-top:@row-padding-vertical/2;
	}
}
.padding-bottom{
	padding-bottom: @row-padding-vertical;
	@media only screen and (max-width: @screen-xs) {
		padding-bottom:@row-padding-vertical/2;
	}
}
.padding-both{
	padding-top: @row-padding-vertical;
	padding-bottom: @row-padding-vertical;
	@media only screen and (max-width: @screen-xs) {
		padding-top:@row-padding-vertical/2;
		padding-bottom:@row-padding-vertical/2;
	}
}

.collection-flash{
	position:relative;
	.caption {
	  position: absolute;
	  bottom: 0;
	  width: 80%;
	  margin: 6% 10%;
	  background: #fff;
	  h2{
	  	text-transform: none;
	  	margin: 50px auto 10px;
	  }
	  .text{
		text-align:center;
		margin: 0 20px 30px;
	  }
	}
}

.news-flash{
	text-align: center;
	h2{
		margin: 44px 0 30px;
	}
	p{
		padding: 0 0 20px;
	}
	margin-bottom: 50px;
}

#infscr-loading{
	text-align:center;
	img{
		width: auto;
		margin:30px;
	}
}

.ias-spinner{
  text-align: center;
  display: block;
  width: 90px;
  margin: auto;
  clear: both;
  padding: 30px;
}

.pagination{
	display:none!important;
	clear:both;
}



.lupa{
	position:absolute;
	display:none;
	width:50px;
	height:50px;
	border-radius:50%;
	/*border:1px solid #000;*/
	background: #fff;
	font-size:32px;
 	text-align: center;
 	line-height: 60px;
 	color: #000;
 	z-index: 10000;
 	top:50%;
 	left:50%;

}
.area-lupa{
	position:relative;
	display:block;
 	cursor: none;
}


/* close buttons */


.menu-close,.item-close{
	position: absolute;
	font-size:29px;
	right: 70px;
	width:60px;
	height:60px;
	background: #fff;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
	z-index: 9999;
	line-height: 70px;
	.transition(0.4s);
	&:hover{
		color: @main-color;
	}
	@media only screen and (max-width: @screen-xs) {
		right: 20px;
	}
	a{
		width: 100%;
		height: 100%;
		color:#000;
		&:hover{
			color: @link-color;
		}
	}
}

.item-close{
	top: 20px;
	right: 20px;
}

.map, .about, .contact, .news{
	color: #000;
	font-size: 15px;
	line-height: 24px;
	margin-bottom: 30px;
	font-weight: 400;
	p, .content div{
		font-size: 15px;
		line-height: 24px;
		margin-bottom: 30px;
		font-weight: 400;
	}
	.social{
		margin: 55px 20px 40px;
		h3{
			display:none;
		}
		ul{
			margin-top: 45px;
			margin-bottom: 65px;
		}
	}
	.novia p{
		margin: -15px 20px -45px;
		text-align: center;
		font-size: 150px;
	}
	.padding-top{
		padding-top: 40px;
	}
	.address{
		margin-bottom: 10px;
		font-size:15px;
	}
	form {
		margin-bottom: 65px;
		margin-top: 100px;
		font-size: 15px;
	}
	.share{
		margin-top:60px;
		border-top: 1px solid @main-color;
		h3{
			margin-bottom:30px;
		}
	}
}

label{
	margin-bottom: 0;
}
.form-group{
	padding-left: 7px;
	padding-right: 7px;
	margin-bottom: 10px;
}
.form-control{
	border: 0;
	height:auto;
	line-height:16px;
	border-radius:0;
	padding: 20px 0;
	font-size:15px;
	border-bottom: 1px solid @main-color;
	color: #000;
	box-shadow: none !important;
}

.checkbox{
	display:inline;
}
input[type="checkbox"]{
	visibility: hidden;
}
.checkbox label{
	padding-left: 26px;
}
.checkbox label:before {
    content: "";
    color: @main-color;
	display: inline-block;
	border: 1px solid @main-color;
	width: 20px;
	height: 20px;
	margin-right: 10px;
	position: absolute;
	left: 0;
	bottom: 1px;
	background-color: #fff;
}
input[type=checkbox]:checked + label:before {
    content: "\2713";
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    font-size: 15px;
    color: #d3b399;
    text-align: center;
    line-height: 15px;
}

span.has-error, span.help-block{
	display:none!important;
}

.has-error .form-control{
	border-color: red!important;
}
.form-control:focus{
	border-color: @main-color!important;
}

body, label, .checkbox label{
	font-weight:normal;
}

.submit{
	border: 1px solid #fff;
	background: #fff;
	text-transform:uppercase;
	font-size: 15px;
	color: @main-color;
	margin-top: -2px;
	&:hover{
		color: #000;
	}
}

.language_bar_chooser{
	li{
		display:inline-block;
		padding:0;
		margin:0;
		a{
			border-right: 1px solid;
			padding: 0 6px 0 4px;
		}
		&:last-child a{
			border-right:0;
		}
	}
}


.social, .share{
		
		margin: 20px 20px 0;
		text-align:center;
		font-size:30px;

		ul li{
			display: inline-block;
			margin-bottom: 0;
			padding: 0 10px;
			.icon{
				font-size:30px!important;
			}
			&:first-child{
				padding: 0 10px 0 0;
			}
			&:last-child{
				padding: 0 0 0 10px;
			}
			@media only screen and (max-width: 1025px) {
				padding: 0 5px 0 !important;

				&:first-child{
					padding: 0 5px 0 0!important;
				}
				&:last-child{
					padding: 0 0 0 5px!important;
				}
			}
		}
		margin: 20px 20%;
		&.full{
			margin: 20px:0;
		}
		@media only screen and (max-width: @screen-sm) {
			margin: 15px 15%;
		}

		@media only screen and (max-width: @screen-sm) {
			margin: 15px 5%;
		}
		text-align:center;
		font-size:30px;
		h3{
			color: #000;
			.proxima(600);
			font-size:14px
		}
		ul{
			list-style: none;
			margin:0;
			padding:0;
			li{
				display: inline-block;
				padding: 0 10px 0;
				a:hover{
					color:#000;
				}
				&:first-child{
					padding: 0 10px 0 0;
				}
				&:last-child{
					padding: 0 0 0 10px;
				}
			}
		}
}

.news{
	margin-bottom: 100px;
}

.news-flash .social{
	margin: 20px 0;
}

::-webkit-input-placeholder { color:#555; }
::-moz-placeholder { color:#555; } /* firefox 19+ */
:-ms-input-placeholder { color:#555; } /* ie */
input:-moz-placeholder { color:#555; }

#name, #email, #phone, #message{
	&::-webkit-input-placeholder { color: #000; }
	&::-moz-placeholder { color: #000; }
	&:-ms-input-placeholder { color:#555; }
}

*:hover{
	.transition(0s)!important;
}


//variable overrides
@import "novias";