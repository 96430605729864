@main-color: #d3b399;
@main-color-negative: #ffffff;
@link-color: @main-color;
@link-hover-color:  @main-color;
@link-darl-color:   #cfae93;

@grid-gutter-width: 0px;
@container-large-desktop: 90%;

@list-caption-bg: rgba(255, 255, 255, 0.9);
@list-name: @main-color-negative;
@list-name-hover: @main-color;

@row-padding-vertical: 30px;
@row-padding: 40px;
@row-padding-item: 40px;
@row-inside-margin: 25px;
@row-inside-margin2: 40px;

.vertical-align() {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.vertical-align2() {
  position: relative;
  bottom: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
.proxima(@weight:300){
  .-(@weight); 
  .-(300)  {@font: 'proxima_nova_regular', sans-serif}
  .-(600) {@font: 'proxima_nova_semibold', sans-serif}
  font-family: @font;
  font-weight: 100;
  /*font-weight: @weight;*/
}